import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartLine,
  faHospitalUser,
  faMagnifyingGlassLocation,
  faBars,
  faFileInvoiceDollar,
  faMapLocationDot,
  faClipboardList,
  faCalculator,
  faStethoscope,
  faUserMd,
  faChartPie,
  faHandHoldingDollar
} from "@fortawesome/free-solid-svg-icons";

function Drawer({ isOpen, toggleDrawer }) {
  const navItems = [
    {
      to: "/app/dashboard",
      icon: faChartPie,
      label: "Dashboard",
      description: "Overview and analytics"
    },
    {
      to: "/app/map",
      icon: faMapLocationDot,
      label: "Map",
      description: "Geographic charge analysis"
    },
    {
      to: "/app/chargesheets",
      icon: faClipboardList,
      label: "ChargeSheet",
      description: "Manage charge sheets"
    },
    {
      to: "/app/chargeestimator",
      icon: faCalculator,
      label: "Estimator",
      description: "Calculate charge estimates"
    },
    {
      to: "/app/analysis",
      icon: faChartLine,
      label: "Analysis",
      description: "Detailed charge analytics"
    },
    {
      to: "/app/chargemaster",
      icon: faHandHoldingDollar,
      label: "ChargeMaster",
      description: "Master charge management"
    },
    {
      to: "/app/eligibilitytool",
      icon: faStethoscope,
      label: "Eligibility",
      description: "Check patient eligibility"
    },
    {
      to: "/app/singlechargesearch",
      icon: faMagnifyingGlassLocation,
      label: "Single Charge Search",
      description: "Search specific charges"
    }
  ];

  return (
    <div
      className={`fixed top-0 left-0 h-screen bg-gray-800/95 backdrop-blur-sm text-white transition-all duration-300 ${
        isOpen ? "w-64" : "w-16"
      } z-40 shadow-xl`}
    >
      <div className="flex justify-between items-center p-4">
        <NavLink to="/app" className="w-full">
          <img 
            src="/logoName.png" 
            alt="SmithTech Solutions" 
            className={`mt-2 transition-opacity duration-300 ${isOpen ? 'opacity-100' : 'opacity-0'}`} 
          />
        </NavLink>
        <button 
          onClick={toggleDrawer} 
          className="p-2 hover:bg-gray-700 rounded-full transition-colors duration-200"
          aria-label={isOpen ? "Collapse menu" : "Expand menu"}
        >
          <FontAwesomeIcon icon={faBars} size="lg" />
        </button>
      </div>
      <nav className="mt-5">
        <ul className="space-y-2">
          {navItems.map((item) => (
            <NavItem key={item.to} to={item.to} icon={item.icon} isOpen={isOpen} label={item.label} description={item.description} />
          ))}
        </ul>
      </nav>
    </div>
  );
}

function NavItem({ to, icon, isOpen, label, description }) {
  return (
    <li>
      <NavLink to={to} className="flex items-center p-4 hover:bg-gray-700 text-white">
        <FontAwesomeIcon icon={icon} className="w-5 h-5" />
        {isOpen && <span className="ml-4">{label}</span>}
      </NavLink>
    </li>
  );
}

export default Drawer;
